// ** React Imports
import { Fragment, lazy } from "react"
import { Navigate } from "react-router-dom"
// ** Layouts
import BlankLayout from "@layouts/BlankLayout"
import VerticalLayout from "@src/layouts/VerticalLayout"
import HorizontalLayout from "@src/layouts/HorizontalLayout"
import LayoutWrapper from "@src/@core/layouts/components/layout-wrapper"

// ** Route Components
import PublicRoute from "@components/routes/PublicRoute"

// ** Utils
import { isObjEmpty } from "@utils"
import PostCategories from "../../views/PostCategories"

const getLayout = {
  blank: <BlankLayout />,
  vertical: <VerticalLayout />,
  horizontal: <HorizontalLayout />
}

// ** Document title
const TemplateTitle = "%s - Diaspora"

// ** Default Route
const DefaultRoute = "/"

const Home = lazy(() => import("../../views/Home"))
const Post = lazy(() => import("../../views/Post"))
const Offers = lazy(() => import("../../views/Offers"))
const AccountOffers = lazy(() => import("../../views/AccountOffers"))
const Offer = lazy(() => import("../../views/Offer"))
const OffersCategory = lazy(() => import("../../views/OffersCategory"))
const Settings = lazy(() => import("../../views/Settings"))
const AccountSettings = lazy(() => import("../../views/AccountSettings"))
const News = lazy(() => import("../../views/News"))
// const Login = lazy(() => import("../../views/Login"))
// const Register = lazy(() => import("../../views/Register"))
const ForgotPassword = lazy(() => import("../../views/ForgotPassword"))
const Error = lazy(() => import("../../views/Error"))
const Chat = lazy(() => import("../../views/Chat"))
const EmailVerification = lazy(() => import("../../views/user/auth/EmailVerification"))

// const MainPage = lazy(() => import('../../pages/MainPage'))
// const Home = lazy(() => import('../../pages/Home'))
// const Badge = lazy(() => import('../../pages/Badge'))
// const Group = lazy(() => import('../../pages/Group'))
// const Storie = lazy(() => import('../../pages/Storie'))
// const Member = lazy(() => import('../../pages/Member'))
// const Email = lazy(() => import('../../pages/Email'))
// const Emailopen = lazy(() => import('../../pages/Emailopen'))
// const Settings = lazy(() => import('../../pages/Settings'))
// const Account = lazy(() => import('../../pages/Account'))
// const Contactinfo = lazy(() => import('../../pages/Contactinfo'))
// const Socialaccount = lazy(() => import('../../pages/Socialaccount'))
// const Password = lazy(() => import('../../pages/Password'))
// const Payment = lazy(() => import('../../pages/Payment'))
// const Notification = lazy(() => import('../../pages/Notification'))
// const Helpbox = lazy(() => import('../../pages/Helpbox'))
// const Register = lazy(() => import('../../pages/Register'))
// const Forgot = lazy(() => import('../../pages/Forgot'))
// const Notfound = lazy(() => import('../../pages/Notfound'))
// const ShopOne = lazy(() => import('../../pages/ShopOne'))
// const ShopTwo = lazy(() => import('../../pages/ShopTwo'))
// const ShopThree = lazy(() => import('../../pages/ShopThree'))
// const Singleproduct = lazy(() => import('../../pages/Singleproduct'))
// const Cart = lazy(() => import('../../pages/Cart'))
// const Checkout = lazy(() => import('../../pages/Checkout'))
// const Chat = lazy(() => import('../../pages/Chat'))
// const Live = lazy(() => import('../../pages/Live'))
// const Job = lazy(() => import('../../pages/Job'))
// const Event = lazy(() => import('../../pages/Event'))
// const Hotel = lazy(() => import('../../pages/Hotel'))
// const Videos = lazy(() => import('../../pages/Videos'))
// const Comingsoon = lazy(() => import('../../pages/Comingsoon'))
// const Grouppage = lazy(() => import('../../pages/Grouppage'))
// const Userpage = lazy(() => import('../../pages/Userpage'))
// const Authorpage = lazy(() => import('../../pages/Authorpage'))
// const Hotelsingle = lazy(() => import('../../pages/Hotelsingle'))
// const Analytics = lazy(() => import('../../pages/Analytics'))

// ** Merge Routes
const Routes = [
  // {
  //   path: "/",
  //   element: <MainPage/>
  // },
  // {
  //     path: "/home",
  //     element: <Home/>
  // },
  // {
  //     path: "/defaultbadge",
  //     element: <Badge/>
  // },
  // {
  //     path: "/defaultgroup",
  //     element: <Group/>
  // },
  // {
  //     path: "/defaultstorie",
  //     element: <Storie/>
  // },
  // {
  //     path: "/defaultemailbox",
  //     element: <Email/>
  // },
  // {
  //     path: "/defaultemailopen",
  //     element: <Emailopen/>
  // },
  // {
  //     path: "/defaultsettings",
  //     element: <Settings/>
  // },
  // {
  //     path: "/defaultvideo",
  //     element: <Videos/>
  // },
  // {
  //     path: "/defaultanalytics",
  //     element: <Analytics/>
  // },
  // {
  //     path: "/accountinformation",
  //     element: <Account/>
  // },
  // {
  //     path: "/defaultmember",
  //     element: <Member/>
  // },
  // {
  //     path: "/contactinformation",
  //     element: <Contactinfo/>
  // },
  // {
  //     path: "/socialaccount",
  //     element: <Socialaccount/>
  // },
  // {
  //     path: "/password",
  //     element: <Password/>
  // },
  // {
  //     path: "/payment",
  //     element: <Payment/>
  // },
  // {
  //     path: "/defaultnoti",
  //     element: <Notification/>
  // },
  // {
  //     path: "/helpbox",
  //     element: <Helpbox/>
  // },
  // {
  //     path: "/login",
  //     element: <Login/>,
  //     meta: {
  //         layout: "blank"
  //     }
  // },
  // {
  //     path: "/register",
  //     element: <Register/>,
  //     meta: {
  //         layout: "blank"
  //     }
  // },
  // {
  //     path: "/forgot",
  //     element: <Forgot/>,
  //     meta: {
  //         layout: "blank"
  //     }
  // },
  // {
  //     path: "/notfound",
  //     element: <Notfound/>,
  //     meta: {
  //         layout: "blank"
  //     }
  // },
  // {
  //     path: "/shop1",
  //     element: <ShopOne/>
  // },
  // {
  //     path: "/shop2",
  //     element: <ShopTwo/>
  // },
  // {
  //     path: "/shop3",
  //     element: <ShopThree/>
  // },
  // {
  //     path: "/singleproduct",
  //     element: <Singleproduct/>
  // },
  // {
  //     path: "/cart",
  //     element: <Cart/>
  // },
  // {
  //     path: "/checkout",
  //     element: <Checkout/>
  // },
  // {
  //     path: "/defaultmessage",
  //     element: <Chat/>
  // },
  // {
  //     path: "/defaultlive",
  //     element: <Live/>
  // },
  // {
  //     path: "/defaultjob",
  //     element: <Job/>
  // },
  // {
  //     path: "/defaultevent",
  //     element: <Event/>
  // },
  // {
  //     path: "/defaulthotel",
  //     element: <Hotel/>
  // },
  // {
  //     path: "/grouppage",
  //     element: <Grouppage/>
  // },
  // {
  //     path: "/userpage",
  //     element: <Userpage/>
  // },
  // {
  //     path: "/authorpage",
  //     element: <Authorpage/>
  // },
  // {
  //     path: "/comingsoon",
  //     element: <Comingsoon/>
  // },
  // {
  //     path: "/defaulthoteldetails",
  //     element: <Hotelsingle/>
  // },
  {
    path: "/",
    element: <Home />
  },
  // {
  //   path: "/login",
  //   element: <Login />,
  //   meta: {
  //     layout: "blank"
  //   }
  // },
  // {
  //   path: "/register",
  //   element: <Register />,
  //   meta: {
  //     layout: "blank"
  //   }
  // },
  {
    path: "/email-verified",
    element: <EmailVerification />,
    meta: {
      layout: "blank"
    }
  },
  {
    path: "/posts/:postId",
    element: <Post />
  },
  {
    path: "/posts-categories/:categoryId",
    element: <PostCategories />
  },
  {
    path: "/posts",
    element: <News />
  },
  {
    path: "/offers",
    element: <Offers />
  },
  {
    path: "/offers/me",
    element: <AccountOffers />
  },
  {
    path: "/offers-categories/:categoryId",
    element: <OffersCategory />
  },
  {
    path: "/offers/:offerId",
    element: <Offer />
  },
  {
    path: "/settings",
    element: <Settings />
  },
  {
    path: "/settings/account",
    element: <AccountSettings />
  },
  {
    path: "/forgot-password",
    element: <ForgotPassword />,
    meta: {
      layout: "blank"
    }
  },
  {
    path: "/error",
    element: <Error />,
    meta: {
      layout: "blank"
    }
  },
  {
    path: "/chat",
    element: <Chat />
  }
]

const getRouteMeta = (route) => {
  if (isObjEmpty(route.element.props)) {
    if (route.meta) {
      return { routeMeta: route.meta }
    } else {
      return {}
    }
  }
}

// ** Return Filtered Array of Routes & Paths
const MergeLayoutRoutes = (layout, defaultLayout) => {
  const LayoutRoutes = []

  if (Routes) {
    Routes.filter((route) => {
      let isBlank = false
      // ** Checks if Route layout or Default layout matches current layout
      if (
        (route.meta && route.meta.layout && route.meta.layout === layout) ||
        ((route.meta === undefined || route.meta.layout === undefined) &&
          defaultLayout === layout)
      ) {
        const RouteTag = PublicRoute

        // ** Check for public or private route
        if (route.meta) {
          route.meta.layout === "blank" ? (isBlank = true) : (isBlank = false)
        }
        if (route.element) {
          const Wrapper =
            // eslint-disable-next-line multiline-ternary
            isObjEmpty(route.element.props) && isBlank === false
              ? // eslint-disable-next-line multiline-ternary
                LayoutWrapper
              : Fragment

          route.element = (
            <Wrapper {...(isBlank === false ? getRouteMeta(route) : {})}>
              <RouteTag route={route}>{route.element}</RouteTag>
            </Wrapper>
          )
        }

        // Push route to LayoutRoutes
        LayoutRoutes.push(route)
      }
      return LayoutRoutes
    })
  }
  return LayoutRoutes
}

const getRoutes = (layout) => {
  const defaultLayout = "vertical"
  const layouts = ["vertical", "blank"]

  const AllRoutes = []

  layouts.forEach((layoutItem) => {
    const LayoutRoutes = MergeLayoutRoutes(layoutItem, defaultLayout)
    AllRoutes.push({
      path: "/",
      element: getLayout[layoutItem] || getLayout[defaultLayout],
      children: LayoutRoutes
    })
  })
  return AllRoutes
}

export { DefaultRoute, TemplateTitle, Routes, getRoutes }
