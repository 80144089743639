// ** React Imports
import { Outlet } from "react-router-dom"
import Header from '@components/Header'
import Leftnav from '@components/Leftnav'
import Appfooter from '@components/Appfooter'
import Footer from '@components/Footer'

const VerticalLayout = () => {
  // const [menuData, setMenuData] = useState([])

  // ** For ServerSide navigation
  // useEffect(() => {
  //   axios.get(URL).then(response => setMenuData(response.data))
  // }, [])

  return (
    <>
        <Header />
        <Leftnav />
        <Outlet />
        <Appfooter />
    </>
  )
}

export default VerticalLayout
